.hotline_btn {
    width: 60px;
    height: 60px;
    position: fixed;
    right: 24px;
    bottom: 100px;
    background: #e57905;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    box-shadow: 0px 8px 36px rgb(0 0 0 / 17%);
    z-index: 99;
}

.modal-text:hover {
    color: var(--orangeColor);
}
