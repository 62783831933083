.menu-list-home {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    .menu-banner {
        flex-basis: calc(50% - 30px);
        margin: 0 15px 40px 15px;

        a {
            display: block;
            padding-top: 65%;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 4px 16px 0px #0000001f;

            img {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
            }
        }
    }

    .menu-item {
        background: transparent;
        flex-basis: calc(25% - 30px);
        margin: 0 15px 40px;
        min-height: 145px;

        .menu-item-img {
            position: relative;

            a {
                border-radius: 10px;
                overflow: hidden;
                display: block;
                width: 100%;
                padding-top: 100%;
                position: relative;
                box-shadow: 0px 0px 13px 0px #00000040;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }
        }

        .menu-item-info {
            padding: 12px 0 0;

            h3 {
                margin-top: 0;
                margin-bottom: 4px;
                font-weight: 600;
                color: #191919;
                font-size: 16px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;

                a:hover {
                    color: var(--orangeColor);
                }

                .price-product-item {
                    font-size: 14px;
                    color: #00000099;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

/*-----------------------------------*\
 * #Media query
\*-----------------------------------*/

@media (max-width: 768px) {
    .menu-list-home {
        display: flex;

        .menu-banner {
            flex: 0 0 calc(100% - 30px);
            margin: 0 15px 40px 15px;
        }

        .menu-item {
            flex: 0 0 calc(50% - 30px);
        }
    }
}
