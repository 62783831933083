.home-cloudtea {
    padding: 48px 0 45px;
    font-weight: 400;
    background: #45392c
        url('https://file.hstatic.net/1000075078/file/cloudtea-bg-2x_9f42dcb41fd04a8e817922b2d6edad32.jpg')
        top center/cover no-repeat;
}

.cloudtea-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: warp;
    margin-bottom: 8px;

    .cloudtea-right {
        flex: 0 0 49%;
        width: 49%;

        img {
            width: 100%;
        }
    }

    .cloudtea-left {
        flex: 0 0 49%;
        width: 49%;
        order: 2;

        .cloudtea-title {
            margin-bottom: 12px;
        }

        .cloudtea-desc {
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 16px;
        }

        .cloudtea-btn {
            display: block;
            max-width: 282px;
            background: #b3ddca;
            text-align: center;
            font-size: 16px;
            line-height: 40px;
            padding: 0 15px;
            font-weight: 600;
            border-radius: 8px;
            color: #fff;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

/*-----------------------------------*\
 * #Media query
\*-----------------------------------*/

@media (max-width: 600px) {
    .home-cloudtea {
        padding: 40px 0 56px;
        background: url('https://file.hstatic.net/1000075078/file/cloudtea-bg-2x_9f42dcb41fd04a8e817922b2d6edad32.jpg')
            top center/100% no-repeat;
    }

    .cloudtea-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 8px;

        .cloudtea-right {
            width: 100%;
        }

        .cloudtea-left {
            width: 100%;
            margin-top: 20px;

            .cloudtea-btn {
                max-width: 100%;
            }
        }
    }
}
