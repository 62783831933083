* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

body {
    line-height: 1.42857143;
    font-size: 14px;
    color: #000;
    background: #fff;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: #191919;
    transition: 0.5s;
}

li {
    list-style-type: none;
}

img {
    border: none;
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.4;
    color: #000;
    font-weight: 600;
}

:root {
    --orangeColor: #ea8025;
}

/*-----------------------------------*\
 * #USED SASS
\*-----------------------------------*/

.container {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;
}

@media (max-width: 992px) {
    .container {
        width: 960px;
    }
}

@media (max-width: 768px) {
    .container {
        width: 740px;
    }
}

@media (max-width: 576px) {
    .container {
        width: 100%;
    }
}

@media (max-width: 376px) {
    .container {
        width: 100%;
    }
}
