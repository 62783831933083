@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@400;500;700&family=Source+Sans+Pro:wght@400;600;700&display=swap');

.footer {
    background: #000000d9;
    padding-top: 34px;
    padding-bottom: 54px;
}

.footer_container {
    hr {
        margin-top: 20px;
        margin-bottom: 20px;
        border: 0;
        border-top: 2px solid #fff;
    }
}

.footer_title {
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    margin-top: 0;
    margin-bottom: 16px;
}

.footer_linklists {
    padding: 0;
    margin: 0;

    li {
        margin-bottom: 8px;
        font-size: 14px;

        a {
            color: #fff;
            line-height: 20px;
            font-family: 'Lato', sans-serif;
        }
    }
}

.hotline_footer {
    p {
        font-weight: 600;
        margin-bottom: 24px;
        color: #fff;

        img {
            margin-right: 10px;
        }
    }
}

.address_footer {
    p {
        color: #fff;
        line-height: 20px;
        margin: 0;
        font-size: 12px;
    }
}

.footer_social_link {
    display: flex;
    padding: 0;
    margin: 0;
    margin-top: 15px;
    gap: 15px;
}

.footer_img {
    img {
        box-shadow: rgb(149 146 146) 0px 20px 30px -10px;
    }
}

.footer_itemlist {
    padding: 0;
    margin: 0;

    li {
        font-size: 12px;
        color: #fff;
        line-height: 18px;
        font-family: 'Lato', sans-serif;
    }
}

/*-----------------------------------*\
 * #Media query
\*-----------------------------------*/

@media (max-width: 768px) {
    .footer_container {
        .footer-item-gt {
            margin-bottom: 25px;
        }
    }
}

@media (max-width: 600px) {
    .footer_container {
        .footer-item-gt {
            margin-bottom: 25px;
        }

        .footer-item-hl {
            margin-bottom: 25px;
        }
    }
}
