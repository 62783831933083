.top-bar {
    .container {
        display: flex;
        padding: 10px 0;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;

        .top-bar-item {
            padding: 0 4.2%;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                color: #00000099;

                &:hover {
                    color: var(--orangeColor);
                }

                img {
                    margin-right: 5px;
                }
            }
        }
    }
}

.header {
    border-bottom: 1px solid #00000026;
    position: sticky;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 999;
    height: 60px;
    width: 100%;
}

.header-nav {
    display: flex;
    align-items: center;
    background-color: transparent;

    .nav-logo {
        a {
            img {
                width: 200px;
                transform: translateY(-10px);
                margin-right: 25px;
            }
        }
    }

    .sidebar {
        display: none;
    }

    .nav-menu {
        .nav-menu-lv1 {
            display: flex;
            align-items: center;
            white-space: nowrap;

            .nav-menu-item-lv1 {
                display: flex;
                align-items: center;
                padding: 19px 16px 19px;

                a {
                    display: block;
                    color: #000;
                    font-size: 16px;
                    line-height: 22px;
                    font-weight: 500;
                    margin-right: 5px;

                    &:hover,
                    &:focus {
                        color: var(--orangeColor);
                    }
                }
            }
        }
    }
}

.nav-menu-item-lv1:hover .nav-menu-lv2 {
    top: 100%;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    background: rgba(255, 255, 255, 0.95);
}

.nav-menu-lv2 {
    display: flex;
    padding: 24px 15.4% 32px;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    pointer-events: none;
    position: absolute;
    top: 200%;
    left: 0;
    z-index: 99;
    background: #fff;
    color: #000;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .lv2-title {
        flex: 1;
        padding: 12px 2.2%;
        float: none;
        display: block;

        > a {
            color: #000;
            border-bottom: 2px solid;
            font-size: 18px;
        }

        .menu-child-lv3 {
            padding: 0;
            margin-top: 16px;
            width: 120%;

            .lv3-title {
                margin-bottom: 7px;

                a {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.6);
                }
            }
        }
    }
}

/*-----------------------------------*\
 * #Media query
\*-----------------------------------*/

@media (max-width: 1225px) {
    .header-nav .nav-menu .nav-menu-lv1 .nav-menu-item-lv1:nth-child(7) {
        display: none;
    }
}

@media (max-width: 1024px) {
    .header-nav {
        justify-content: center;
        line-height: 70px;

        .sidebar {
            display: block;
            position: absolute;
            left: 0;

            img {
                width: 28px;
                line-height: 8px;
                margin-bottom: 15px;
                margin-left: 10px;
            }
        }
    }

    .header-nav .nav-menu .nav-menu-lv1 {
        display: none;
    }

    .nav-logo {
        margin-left: 25px;
    }
}
