.block_item_menu {
    padding: 40px 0 50px;

    .block_item_menu_title {
        h3 {
            font-size: 24px;
            margin-bottom: 24px;
            font-weight: 500;
        }
    }
}

.menu_list_collection {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;

    .menu_item {
        flex: 0 0 calc(25% - 30px);
        margin: 0 15px 40px;
        min-height: 145px;

        .menu_img {
            position: relative;

            a {
                border-radius: 10px;
                overflow: hidden;
                display: block;
                width: 100%;
                padding-top: 100%;
                position: relative;
                box-shadow: 0px 0px 13px 0px #00000040;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        .menu_info {
            padding: 12px 0 0;

            h3 {
                margin-top: 0;
                margin-bottom: 4px;
                font-weight: 500;
                color: #191919;
                font-size: 16px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
            }

            p {
                font-size: 14px;
                color: #00000099;
                margin-bottom: 10px;
            }
        }
    }
}

/*-----------------------------------*\
 * #RESPONSIVE
\*-----------------------------------*/

@media (max-width: 768px) {
    .menu_list_collection {
        .menu_item {
            flex: 0 0 calc(50% - 30px);
        }
    }
}
